import { area, getOrganBsdt, getWorkOffice } from '../../api/uums';
import { localDataArea } from '@iebdc/utils/local-data.js';

/**
 * 初始化缓冲数据
 */
export function initData(callback) {
  Promise.all([area(), getOrganBsdt(), getWorkOffice({ nSize: 10000 })]).then(([r1, r2, r3]) => {
    let allAreaData = r1.data;
    let allOrgData = r2.data;
    let bsdtData = r3.data.page;
    rebuildAllData(allAreaData, allOrgData, bsdtData);
    let finalAreaData = getAllNodes(allAreaData, 'childrens').filter((item) => {
      return item.code === window.CONFIG.code;
    });
    // 行政区
    localDataArea.set(finalAreaData);
    callback();
  });
}

export function rebuild() {
  const findByAreaId = (arr) => {};

  const buildDeep = (arr) => {};

  buildDeep();
}

function rebuildAllData(areaData, orgData, bsdtData) {
  //重构 bsdtData 数据，给办事大厅加上所属区县的areaId
  rebuildBsdtData(orgData, bsdtData);
  //重构areaData 数据, 将区县与办事大厅通过areaId连接后的bsdtDat,放入areaData的相同的areaId的bsdtData属性里
  let allAreaData = getAllNodes(areaData, 'childrens');
  for (let ii = 0; ii < allAreaData.length; ii++) {
    let areaItem = allAreaData[ii];
    areaItem.bsdtData = [];
    for (let jj = 0; jj < bsdtData.length; jj++) {
      if (areaItem.id === bsdtData[jj].areaId) {
        areaItem.bsdtData.push(bsdtData[jj]);
      }
    }
  }
}
function rebuildBsdtData(orgData, bsdtData) {
  if (orgData === null || bsdtData === null) {
    return false;
  }
  let allOrgData = getAllNodes(orgData, 'childrens');
  for (let ii = 0; ii < bsdtData.length; ii++) {
    let bsdtItem = bsdtData[ii];
    //尝试优化
    // let v = allOrgData.find((item) => {
    //   return item.code == bsdtItem.officeCode;
    // });
    for (let jj = 0; jj < allOrgData.length; jj++) {
      if (allOrgData[jj].code === bsdtItem.officeCode) {
        bsdtItem.areaId = allOrgData[jj].areaId;
        break;
      }
    }
  }
}

/**
 * 扁平化数据
 */
export function getAllNodes(arrayNodes, attChildren) {
  if (arrayNodes === null) {
    return [];
  }
  attChildren = attChildren === null ? 'children' : attChildren;
  let result = [];
  for (let ii = 0; ii < arrayNodes.length; ii++) {
    let pNode = arrayNodes[ii];
    result.push(pNode);
    if (pNode[attChildren]) {
      let arrayTempNodes = getAllNodes(pNode[attChildren], attChildren);
      result = result.concat(arrayTempNodes);
    }
  }
  return result;
}
