<template>
  <div class="mt60">
    <div class="flex-space-between">
      <div>
        <label class="input-label">名称：</label>
        <el-input v-model="bankSearch.userName" placeholder="请输入名称"></el-input>
        <label class="input-label margin-left-base">证件号：</label>
        <el-input v-model="bankSearch.zjh" placeholder="请输入证件号"></el-input>
      </div>
      <div>
        <el-button @click="bankQuery" type="primary" size="small">查询</el-button>
        <!--        <el-button size="small">重置</el-button>-->
        <el-button @click="batchQuery" type="primary" plain size="small"
          >批量查询</el-button
        >
      </div>
    </div>
    <div class="search-title flex-space-between">
      <span>查询结果</span>
      <el-button @click="exportExcel" type="primary" size="small">导出电子表格</el-button>
    </div>
    <e-table
      v-loading="loading"
      ref="checkRef"
      :columns="columns"
      :options="options"
    ></e-table>
    <div class="text-center mt10">
      <el-button @click="last" type="primary" size="small">返回</el-button>
    </div>
    <BatchQueryInfo @bankQuery="bankQuery" ref="batchQueryInfo" />
  </div>
</template>

<script>
import { localDataUser } from "@iebdc/utils/local-data";
import authorizationSearch from "@iebdc/api/authorization-search";
import BatchQueryInfo from "../model/batch-query-info";
import { getCookie } from "@iebdc/utils/cookies";

export default {
  data() {
    return {
      loading: false,
      bankSearch: {
        userId: "",
        userName: "",
        zjh: "",
        qydm: getCookie("selectedAreaCode"),
      },
      tableList: [],
      columns: [
        {
          label: "姓名",
          prop: "qlrmc",
        },
        {
          label: "不动产权证书（登记证明）号",
          prop: "bdcqzh",
          width: "150",
        },
        {
          label: "坐落",
          prop: "zl",
          width: "200",
        },
        {
          label: "面积",
          prop: "jzmj",
        },
        {
          label: "共有方式",
          prop: "gyfs",
          type: "dic",
        },
        {
          label: "登记时间",
          prop: "djsj",
        },
        {
          label: "房屋状态",
          prop: "fwzt", //是否抵押和是否查封判断的   0 0 未查封未抵押   。。。
          formatter: (scope) => {
            // 拼接是否查封，是否抵押
            let sfcf = "";
            let sfdy = "";
            if (scope.sfcf === "0" || !scope.sfcf) {
              sfcf = "未查封";
            } else {
              sfcf = "已查封";
            }
            if (scope.sfdy === "0" || !scope.sfdy) {
              sfdy = "未抵押";
            } else {
              sfdy = "已抵押";
            }
            return `<span>${sfcf}${sfdy}</span>`;
          },
        },
      ],
      options: {
        pageSize: 1000,
        loading: true,
        hasPagination: false,
        maxHeight: "556px",
      },
      user: localDataUser.get(),
      cxbh: "",
    };
  },
  components: {
    BatchQueryInfo,
  },
  methods: {
    // 银行企业用户-单人查询
    bankQuery(data, type = 1) {
      if (type === 2) {
        this.cxbh = data.cxbh;
        this.tableList = data.bdcxxList;
        this.$refs.checkRef.setTableList(data.bdcxxList);
        return;
      }
      if (!this.bankSearch.userName || !this.bankSearch.zjh)
        return this.$message.warning("名称和证件号不能为空！");
      this.loading = true;
      this.bankSearch.userId = this.user.id;
      authorizationSearch
        .authorizeBankOneInspect(this.bankSearch)
        .then((res) => {
          if (res.success) {
            this.cxbh = res.data.cxbh;
            this.tableList = res.data.bdcxxList;
            this.$refs.checkRef.setTableList(res.data.bdcxxList);
          } else {
            this.$message.warning(res.message);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 重置
    reset() {
      for (let key in this.bankSearch) {
        this.bankSearch[key] = "";
      }
      this.bankQuery();
    },
    // 批量查询
    batchQuery() {
      this.$refs.batchQueryInfo.open();
    },
    // 导入Excel表
    exportExcel() {
      if (!this.tableList || this.tableList.length <= 0)
        return this.$message.warning("请先查询数据！");
      authorizationSearch
        .exportQszmExcel({ userId: this.user.id, cxbh: this.cxbh })
        .then((res) => {
          const fileName = "电子表格";
          let blob = new Blob([res], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName + ".xls");
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + ".xls";
            link.click();
            window.URL.revokeObjectURL(link.href);
            link.remove();
          }
        });
    },
    // 返回上一步
    last() {
      // this.$parent.$emit('last', 0);
      this.$router.go(-1);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.mt10 {
  margin-top: 10px;
}
.mt60 {
  margin-top: 60px;
}
.search-title {
  border-top: 1px solid #ebeef5;
  margin-top: 10px;
  padding: 8px 0;
}
.input-label {
  margin-right: 10px;
}
/deep/ {
  .e-table {
    height: 400px;
  }
  .el-input {
    width: 300px !important;
  }
}
</style>
