<template>
  <el-dialog title="批量查询" width="70%" :visible.sync="dialogVisible">
    <div class="tip">
      <i class="el-icon-warning"></i>
      <span style="margin-left: 4px">每批最多查询30位。</span>
    </div>
    <div class="flex-space-between" style="padding: 0 8px">
      <span>导入文件列表</span>
      <div class="flex-center">
        <el-button @click="downloadSpreadsheet" size="small" class="mr10"
          >下载电子表格模板</el-button
        >
        <el-upload
          action=""
          accept=".xlsx,.xls"
          :limit="limit"
          :on-change="handleChange"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :http-request="uploadFile"
          :show-file-list="false"
        >
          <el-button type="primary" size="small">导入电子表格</el-button>
        </el-upload>
      </div>
    </div>
    <el-table :data="tableList">
      <el-table-column type="index" width="50" label="序号"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="zjh" label="证件号"></el-table-column>
      <el-table-column prop="authorizeStatus" label="状态"></el-table-column>
    </el-table>
    <div slot="footer" class="text-center">
      <el-button :loading="loadingBtn" type="primary" @click="batchQuery"
        >开始查询</el-button
      >
      <el-button type="primary" @click="goBack">返 回</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { localDataUser } from "@iebdc/utils/local-data";
import authorizationSearch from "@iebdc/api/authorization-search";
import { getCookie } from "@iebdc/utils/cookies";

export default {
  data() {
    return {
      dialogVisible: false,
      user: localDataUser.get(),
      tableList: [],
      fileList: [],
      limit: 1,
      loadingBtn: false,
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    // 下载电子表格模板-导出查询模板
    downloadSpreadsheet() {
      authorizationSearch.exportQueryTemplete({ userId: this.user.id }).then((res) => {
        const fileName = "查询模板";
        let blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName + ".xls");
        } else {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName + ".xls";
          link.click();
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }
      });
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    // beforeUpload(file) {
    // let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
    // let size = file.size / 1024 / 1024;
    // if (extension !== 'xls') {
    //   this.$message.warning('只能上传后缀是.xls的文件');
    // }
    // if (size > 10) {
    //   this.$message.warning('文件大小不得超过10M');
    // }
    // },
    // 文件状态改变
    handleChange(file, fileList) {
      console.log(fileList, "fileList");
      console.log(file, "file");
      if (file) {
        this.judgeFileType(fileList, "Excel");
        this.fileList = fileList.slice(-1);
      }
      console.log(this.fileList.length, "fileList");
    },
    //判断文件类型  file 文件 type 文件类型 Excel, Img, File
    judgeFileType(file, type) {
      const filetype = file[0].name
        .substring(file[0].name.lastIndexOf("."))
        .toLowerCase();
      type = type || "File";
      const fileTypeArray = {
        Excel: ".xlsx,.xls",
        Img: ".jpg,.jpeg,.png",
        File: ".jpg,.jpeg,.png,.pdf,.doc,.docx",
      };
      if (fileTypeArray[type].indexOf(filetype) === -1) {
        return false;
      }
      return true;
    },
    // 文件超出个数限制
    handleExceed(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limit} 个文件，当前共选择了 ${files.length + fileList.length} 个`
      );
    },
    // 覆盖默认的上传行为，自定义上传的实现
    uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件");
      } else {
        let formData = new FormData();
        formData.append("multipartFile", this.fileList[0].raw);
        formData.append("userId", this.user.id);
        authorizationSearch
          .authorizeExcelToUser(formData)
          .then((res) => {
            if (res.success && res.data) {
              this.tableList = res.data;
            } else {
              this.$message.warning(res.message);
            }
          })
          .finally(() => {
            this.fileList = [];
          });
      }
    },
    batchQuery() {
      if (this.tableList.length === 0) return this.$message.warning("请先导入文件");
      this.loadingBtn = true;
      const params = {
        userId: this.user.id,
        qydm: getCookie("selectedAreaCode"),
        userQueryList: this.tableList,
      };
      authorizationSearch
        .authorizeBankInspectList(params)
        .then((res) => {
          if (res.success && res.data) {
            this.$message.success("查询成功！");
            this.dialogVisible = false;
            this.$emit("bankQuery", res.data, 2);
          } else {
            this.$message.warning(res.message);
          }
          this.loadingBtn = false;
        })
        .catch(() => {
          this.loadingBtn = false;
        });
    },
    goBack() {
      this.fileList = [];
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  margin: 20px 0;
  padding: 8px 16px;
  background-color: #fff3dd;
  color: #e6a23c;
  font-size: 13px;
  border-radius: 8px;
}
/deep/ .el-dialog__body {
  padding: 0 20px 30px;
}
</style>
