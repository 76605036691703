<template>
  <div class="complete">
    <!--查询结果-->
    <div>
      <div class="title"><span>查询结果</span></div>
      <div class="remind">
        依<span class="font-bold">{{ userName }}</span
        >的申请，经查询{{ title }}不动产登记 "一窗受理" 平台(自助查询系统)，查档编号:{{
          checkData.cxbh
        }}，共有<span class="font-bold">{{
          checkData.bdcxxList ? checkData.bdcxxList.length : 0
        }}</span
        >套房产结果如下:
      </div>
      <!--没有数据-->
      <table
        class="first-child"
        v-if="!checkData.bdcxxList || checkData.bdcxxList.length < 1"
      >
        <tr>
          <td class="bgcolorTr">权利人名称</td>
          <td>{{ checkData.cxsqr }}</td>
          <td class="bgcolorTr">权利人证件号</td>
          <td>{{ checkData.zjh }}</td>
        </tr>
        <tr>
          <td class="bgcolorTr">查询时间</td>
          <td colspan="3">{{ nowDate }}</td>
        </tr>
        <tr>
          <td class="bgcolorTr" colspan="4">本次不动产信息查询共0条记录</td>
        </tr>
      </table>
      <!--有数据-->
      <table
        v-else
        v-for="(item, index) in checkData.bdcxxList"
        :key="index"
        class="tableData first-child"
        border="1"
        cellspacing="0"
      >
        <tr>
          <td
            :rowspan="7"
            style="able-layout: fixed; width: 30px; background-color: white"
          >
            <div class="wt-4">{{ index + 1 }}</div>
          </td>
        </tr>
        <tr>
          <td>权利人</td>
          <td>{{ item.qlrmc }}</td>
          <td>共有方式</td>
          <td><dict-span type="共有方式" :binddata="item.gyfs"></dict-span></td>
        </tr>
        <tr>
          <td>不动产权证号</td>
          <td :colspan="3">{{ item.bdcqzh }}</td>
        </tr>
        <tr>
          <td>坐落</td>
          <td :colspan="3">{{ item.zl }}</td>
        </tr>
        <tr>
          <td>不动产单元号</td>
          <td>{{ item.bdcdyh }}</td>
          <td>登记时间</td>
          <td>{{ item.djsj }}</td>
        </tr>
        <tr>
          <td>现房/期房</td>
          <td>{{ item.sfyg === "1" ? "期房" : "现房" }}</td>
          <td>房屋状态</td>
          <td>
            {{ item.sfdy === "1" ? "已抵押" : "未抵押" }}、{{
              item.sfcf === "1" ? "已查封" : "未查封"
            }}
          </td>
        </tr>
        <tr>
          <td :colspan="4">
            <table class="table2">
              <tr>
                <td class="wt-7">幢号</td>
                <td class="wt-7">户号</td>
                <td class="wt-12">所在层</td>
                <td class="wt-12">总层数</td>
                <td class="wt-12">建筑面积</td>
                <td class="wt-14">套内面积</td>
                <td class="wt-18">房屋结构</td>
                <td class="wt-18">规划用途</td>
              </tr>
              <tr>
                <td class="wt-7">{{ item.zh }}</td>
                <td class="wt-7">{{ item.hh }}</td>
                <td class="wt-12">{{ item.szc }}</td>
                <td class="wt-12">{{ item.zcs }}</td>
                <td class="wt-12">{{ item.jzmj }}</td>
                <td class="wt-14">{{ item.scjzmj }}</td>
                <td class="wt-18">
                  <dict-span type="房屋结构" :binddata="item.fwjg"></dict-span>
                </td>
                <td class="wt-18">
                  <dict-span type="房屋用途" :binddata="item.fwyt"></dict-span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <div class="remind">
        该记录依申请用于<b><dict-span type="查档用途" :binddata="cdyt"></dict-span></b>
      </div>
    </div>
    <div class="jzxx">
      <el-button class="mt30" type="default" @click="backHomepage" size="small"
        >返回大厅</el-button
      >
      <el-button
        :disabled="!checkData.cxbh"
        :loading="loading"
        class="mt30"
        type="primary"
        @click="printQszm"
        size="small"
        >查看并下载</el-button
      >
    </div>
    <el-dialog :visible.sync="visible" custom-class="print-qszm" :fullscreen="true">
      <div class="viewer">
        <iframe :src="printQszmUrl"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import obj from "@iebdc/router/modules/my-query.js";
import utils from "@/utils/utils";
import { printQszm } from "@iebdc/api/my-query.js";
import { localDataUser } from "@iebdc/utils/local-data";

export default {
  name: "queryResult",
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
    cdyt: String,
  },
  data() {
    return {
      visible: false,
      printQszmUrl: "",
      title: window.CONFIG.title,
      loading: false,
      objPath: obj.path,
      nowDate: "",
      userName: localDataUser.get().name,
    };
  },
  methods: {
    backHomepage() {
      this.$router.go(-1);
    },
    printQszm() {
      this.loading = true;
      printQszm({ cxbh: this.checkData.cxbh }).then((res) => {
        // 202008191735141196
        if (res.data && res.success) {
          this.visible = true;
          this.printQszmUrl = res.data;
          // window.open(res.data);
          this.loading = false;
        } else {
          this.$message.warning("下载失败!");
        }
      });
    },
  },
  filters: {
    filterTime: function (val) {
      return val ? utils.dateFtt(val, "yyyy-MM-dd hh:mm:ss") : "";
    },
  },
  watch: {
    cdyt: function (val) {
      console.log(val, "old");
    },
  },
  mounted() {
    this.nowDate = utils.getNowDate(1);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/pages/iebdc/styles/public.scss";
@import "~@/pages/iebdc/styles/common-variables.scss";

/deep/ .print-qszm {
  height: 100%;
  /deep/ .el-dialog__body {
    max-height: calc(100vh - 50px) !important;
  }
  /deep/ .el-dialog__header button .el-icon-close {
    color: #1890ff !important;
    font-size: 25px;
    font-weight: bold;
  }
  .viewer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    iframe {
      width: 100%;
      height: 100vh;
    }
  }
}
.bgcolorTr {
  background: rgb(242, 246, 252);
  text-align: center;
  width: 200px;
}
.mt30 {
  margin-top: 30px;
}
.jzxx {
  text-align: center;
}
.complete {
  .title {
    margin-top: $spacing-large 0;
    width: 100%;
    height: 46px;
    font-weight: bolder;
    font-size: 18px;
    color: #303133;
    span {
      line-height: 46px;
      display: inline-block;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #2c8bff;
        content: "";
      }
    }
  }
  .remind {
    color: #303133;
    margin: $spacing-base 0;
    .font-bold {
      font-weight: bold;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    //padding-bottom: 20px;
    //border: solid #e0e0e0;
    //border-width: 1px 0 0 1px;
    tr td {
      height: 36px;
      border: 1px solid #e0e0e0;
      //border-width: 1px 1px 1px 1px;
      //padding: 0px 10px;
    }
  }
  .tableData > tr > td:nth-child(2n-1) {
    text-align: center;
    background: rgb(242, 246, 252);
    width: 14%;
    word-break: break-all;
  }
  .tableData > tr > td:nth-child(2n) {
    text-align: center;
    width: 36%;
    word-break: break-all;
  }
  .wt-4 {
    word-break: break-all;
    width: 50px;
  }
  .table2 {
    tr:last-child {
      background: white !important;
    }
    .wt-7 {
      width: 7% !important;
      word-break: break-all;
    }
    .wt-12 {
      width: 12% !important;
      word-break: break-all;
    }
    .wt-14 {
      width: 14% !important;
      word-break: break-all;
    }
    .wt-18 {
      width: 18% !important;
      word-break: break-all;
    }
  }
}
</style>
