<template>
  <div class="query-notice-container">
    <h2 class="query-notice-title">{{ systemName }}权属证明查询服务大厅须知及协议</h2>
    <p>
      欢迎使用{{ systemName }}查询系统。
      <!-- <span class="text-red"> -->
      为方便公众申请不动产登记信息查询，现开通网上查询功能。申请人在申请前须仔细阅读本须知。
      <!-- </span> -->
    </p>
    <p>一、不动产产权在新疆维吾尔自治区辖区内，均可登陆新疆维吾尔自治区不动产网上服务大厅查询办理。</p>
    <p>二、新疆维吾尔自治区不动产网上服务大厅采用实名制，申请人必须为不动产权利人本人。</p>
    <p>
      三、在新疆维吾尔自治区不动产网上服务大厅提交申请后，根据各县市不动产登记数据整合情况，1个工作日可完成查询申请的审核确认。
    </p>
    <p>四、本查询记录信息为不动产登记信息，如对查询结果有异议，请联系属地不动产登记中心进行核实。</p>
    <p>五、查询结果确认后，可在线下载加盖电子查询签章的查询结果记录。</p>
    <p>六、新疆维吾尔自治区不动产网上服务大厅提供的查询服务，不收取任何手续费。</p>
    <div class="margin-large text-center">
      <span>申请人当前所在地点：</span>
      <el-cascader size="medium" :options="options" v-model="selectedOptions" @change="handleChange"></el-cascader>
    </div>
    <div class="text-center mt-30">
      <el-checkbox @change="changeBox" name="type" v-model="check">我已阅读以上须知并同意</el-checkbox>
    </div>

    <div class="text-center mt-30">
      <el-button @click="next" type="primary" :disabled="disabled"> 下一步 </el-button>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, CodeToText } from 'element-china-area-data';
export default {
  name: 'queryNotice',
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName,
      check: false,
      options: provinceAndCityData,
      selectedOptions: [],
      sqrqydm: '',
      sqrip: '',
      sqrszd: '',
    };
  },
  methods: {
    next() {
      if (this.sqrqydm === '' || this.sqrszd === '') {
        this.$message.warning('定位失败，请手动选择申请人所在地');
      } else {
        let ipData = {
          sqrqydm: this.sqrqydm,
          sqrip: this.sqrip,
          sqrszd: this.sqrszd,
        };
        this.$emit('next', 2);
        this.$emit('getipData', ipData);
      }
    },
    changeBox(val) {
      // if(val){
      //   this.timeCount = 0;
      // }
    },
    handleChange() {
      var loc = '';
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      this.sqrqydm = this.selectedOptions[this.selectedOptions.length - 1];
      this.sqrszd = loc;
      this.sqrip = returnCitySN.cip;
    },
    showCityInfo() {
      //实例化城市查询类
      var citysearch = new AMap.CitySearch();
      let $this = this;
      //自动获取用户IP，返回当前城市
      console.log(returnCitySN);
      citysearch.getCityByIp(returnCitySN.cip, function (status, result) {
        // let logs = '根据IP定位当前位置为' + result.province + result.city;
        // adcode
        console.log(result);
        console.log(status);
        $this.areaname = result.province;
        let areacode = result.adcode;
        let shengcode = areacode.substr(0, 2) + '0000';
        if (areacode.indexOf('0000') !== -1) {
          shengcode = areacode;
          areacode = areacode.substr(0, 2) + '0100';
        }
        $this.selectedOptions = [shengcode, areacode];
        $this.handleChange();
      });
    },
  },

  mounted() {
    this.showCityInfo();
  },
  computed: {
    disabled() {
      return !this.check;
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-30 {
  margin-top: 30px;
}

/deep/ .el-checkbox__label {
  display: inline;
}

.el-checkbox,
.el-checkbox__input {
  display: inline;
}

.query-notice-container {
  margin: 20px 0;
  padding: 20px 140px;
  // background-color: #FFFFFF;
  text-indent: 25px;
  font-size: 13px;

  .query-notice-title {
    background-color: #f6f8fb;
    height: 74px;
    line-height: 74px;
    text-align: center;
    font-size: 18px;
    color: #101010;
    font-family: SourceHanSansSC-regular;
  }
}

h2 {
  margin: 20px 0;
}

p {
  line-height: 50px;
}

.text-red {
  color: red;
}
</style>
