<template>
  <div>
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXCX:FCXXCX:SFZJ')">
      <appointment-steps :stepsData="steps" :activeStep="activeStep" :currentLabel="currentLabel">
        <template slot="content">
          <div v-if="activeStep === 0">
            <queryNotice1 @next="next" />
          </div>
          <div v-if="activeStep === 1">
            <queryNotice @next="next" @getipData="getipData" />
          </div>
          <div v-if="activeStep === 2">
            <fillInformation
              @next="next"
              :ipData="ipData"
              @last="last"
              @qszmcdbh="qszmcdbh"
              @cxyt="cxyt"
              @qszmFormData="qszmFormData"
            />
          </div>
          <div v-if="activeStep === 3">
            <queryResult :checkData="resultNumber" :cxyt="cxytNum" @back="back" :qszmData="qszmData" />
          </div>
        </template>
      </appointment-steps>
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>
  </div>
</template>

<script>
import queryNotice from './components/query-notice';
import queryNotice1 from './components/query-notice1';
import fillInformation from './components/fill-information';
import queryResult from './components/qrcode-result';
import hasConstruction from '@iebdc/mixin/hasConstruction';
import zwwLogin from '@iebdc/utils/zwwLogin.js';
import { localDataUser } from '../../utils/local-data';
import { initData } from './common';
import { getNodesByParam } from '@iebdc/utils/common';
import { localDataArea } from '@iebdc/utils/local-data.js';
import { getCookie, addCookie, removeCookie } from '@iebdc/utils/cookies';
export default {
  name: 'quanSZMQuery',
  mixins: [hasConstruction],
  components: {
    queryNotice,
    queryNotice1,
    fillInformation,
    queryResult,
  },
  data() {
    return {
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '242px',
        imgHeight: '194px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      activeStep: 0,
      steps: [
        {
          title: '法律法规',
        },
        {
          title: '查询须知',
        },
        {
          title: '填写信息',
        },
        {
          title: '查询结果',
        },
      ],
      currentLabel: '权属证明',
      resultNumber: ' ',
      cxytNum: '',
      qszmData: {},
      ipData: {},
    };
  },
  mounted() {
    console.log("sessionStorage.getItem('flush')", sessionStorage.getItem('flush'));
    if (sessionStorage.getItem('flush') === '1') {
      sessionStorage.removeItem('flush');
      this.$router.go(0);
    }
  },
  methods: {
    next(activeStep) {
      this.activeStep = activeStep;
    },
    // 上一步
    last(lastStep) {
      this.activeStep = lastStep;
    },
    qszmcdbh(num) {
      this.resultNumber = num;
    },
    cxyt(number) {
      this.cxytNum = number;
    },
    qszmFormData(data) {
      this.qszmData = Object.assign(data, this.qszmFormData);
      console.log('this.qszmData', this.qszmData);
    },
    getipData(data) {
      this.ipData = data;
    },
    // 返回
    back(backStep) {
      this.activeStep = backStep;
    },
  },
};
</script>

<style lang="scss" scoped>
.appointment-warp {
  .wrap-left {
    width: 135px;
    height: auto;
    float: left;
  }
  .wrap-right {
    height: auto;
    margin-left: 135px;
  }
}
/deep/.e-table {
  width: 100%;
  .cell {
    white-space: pre-line !important;
  }
}
</style>
