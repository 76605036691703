<template>
  <div class="complete">
    <div class="center">
      <div class="success_bottom">请扫码实名认证</div>
      <div class="success_bottom"><el-image :src="qrcode"></el-image></div>
    </div>

    <div class="bottom">尊敬的用户，您已成功提交。请扫码实名认证后在个人中心查看结果。</div>

    <!--查询结果-->
  </div>
</template>

<script>
import obj from '@iebdc/router/modules/my-query.js';
import utils from '@/utils/utils';
import { printQszm } from '@iebdc/api/my-query.js';
import { localDataUser } from '@iebdc/utils/local-data';
import crateQrcode from '@iebdc/plugins/createQRcode.js';
import { userInfo } from '../../../api/login';
export default {
  name: 'queryResult',
  props: {
    checkData: {}, //  查档编号
    cdyt: String,
    qszmData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      qrcode: '',
      visible: false,
      printQszmUrl: '',
      title: window.CONFIG.title,
      loading: false,
      objPath: obj.path,
      nowDate: '',
      userName: localDataUser.get().name,
      userInfo: localDataUser.get(),
    };
  },
  methods: {
    backHomepage() {
      this.$router.go(-1);
    },
    printQszm() {
      this.loading = true;
      printQszm({ cxbh: this.checkData.cxbh }).then((res) => {
        // 202008191735141196
        if (res.data && res.success) {
          this.visible = true;
          this.printQszmUrl = res.data;
          // window.open(res.data);
          this.loading = false;
        } else {
          this.$message.warning('下载失败!');
        }
      });
    },
    getEwm(row) {
      let _this = this;
      //   console.log(window.CONFIG.APP_INFO.klmypayAddress);
      console.log(this.qszmData);
      let url = this.$decryptByDES(window.CONFIG.APP_INFO.certificationUrl);
      let name = null;
      let zjh = null;
      if(this.userInfo.userCategory === 'NORMAL'){
        name =this.qszmData.qlrmc
        zjh = this.qszmData.zjh
      } else {
        name = this.userInfo.frmc;
        zjh = this.userInfo.frzjh;
      }
      console.log('name', name)
      url =
        url +
        '?userName=' +
        this.$encryptByDES(name) +
        '&idCard=' +
        this.$encryptByDES(zjh) +
        '&receiveSeqNo=' +
        this.checkData.receiveSeqNo;
      Promise.all([crateQrcode(url)])
        .then((src) => {
          _this.qrcode = src[0];
          this.dialogVisible = true;
        })
        .catch(() => {
          this.$message.error('获取二维码失败，请联系管理员');
        });
    },
  },
  filters: {
    filterTime: function (val) {
      return val ? utils.dateFtt(val, 'yyyy-MM-dd hh:mm:ss') : '';
    },
  },
  watch: {
    cdyt: function (val) {
      console.log(val, 'old');
    },
  },
  mounted() {
    console.log('checkData=', this.checkData);
    this.nowDate = utils.getNowDate(1);
    this.getEwm();
  },
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';

.center {
  margin-left: 50px;
  margin-right: 50px;
  border-bottom: 1px dashed;
  padding-top: 50px;
}

.success {
  margin: 20px 0;
  text-align: center;
  font-size: 30px;
  color: #0168b7;
}

span {
  color: orange;
  font-size: 30px;
  padding-right: 10px;
}
.success_bottom {
  text-align: center;
  font-size: 16px;
  color: orange;
  margin-bottom: 20px;
}
/deep/.el-image {
    width: 258px;
    height: 260px;
    border: 1px solid #ffffff;
  }
.bottom {
  text-align: center;
  margin-top: 30px;
}
</style>
